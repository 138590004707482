<template>
  <sb-page-content title="Vehicles Capture Request">
    <v-container class="pa-0">
      <sb-single-vehicle-capture-dialog v-if="showVehicleCaptureDialog"
                                        :client-id="vehicleCapture.vehicleCapture.customerInfomation.fleetAccountNumber"
                                        :show-dialog="showVehicleCaptureDialog"
                                        :vehicle-entity="vehicleEntity"
                                        @close="onModalClose"
                                        @vehicle-item="addVehicleItem" />
      <v-card hover>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h4 font-weight-bold">
              <v-tooltip right>
                <template #activator="{on}">
                  <span v-on="on">
                    {{ vehicleCapture.serviceRequestNumber }}
                  </span>
                </template>
                <span>Vehicle Upload Number</span>
              </v-tooltip>
            </v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold">
              Client Number: {{ vehicleCapture.vehicleCapture.customerInfomation.fleetAccountNumber }}
            </v-list-item-subtitle>
            <v-row>
              <v-col cols="12">
                <v-tooltip top>
                  <template #activator="{on}">
                    <div class="text-body-1 font-weight-bold"
                         v-on="on">
                      <v-icon left>
                        mdi-account
                      </v-icon>
                      {{ vehicleCapture.vehicleCapture.customerInfomation.clientName }}
                    </div>
                  </template>
                  <span>Client Name</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-list-item-subtitle class="font-weight-bold">
              Count of Vehicles to be Added: {{ vehicleCount }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-row>
              <v-col class="py-0">
                <v-tooltip top>
                  <template #activator="{on}">
                    <v-chip label
                            dark
                            tag="div"
                            :color="getStatusColor(vehicleCapture.status.value)"
                            v-on="on">
                      {{ vehicleCapture.status.value }}
                    </v-chip>
                  </template>
                  <span>Status</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-list-item-action>
        </v-list-item>

        <v-card-text class="pt-0">
          <v-row>
            <v-col cols="12"
                   sm="3">
              <div class="text-body-1 font-weight-bold">
                Load Date and Time
              </div>
              {{ vehicleCapture.createdTimestamp }}
            </v-col>
            <v-col cols="1"
                   class="text-center">
              <v-divider vertical />
            </v-col>
            <v-col cols="12"
                   sm="3">
              <div class="text-body-1 font-weight-bold">
                Submission Date and Time
              </div>
              {{ vehicleCapture.lastModifiedTimestamp }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <sb-card :title="currentTitle">
        <v-stepper v-model="step"
                   alt-labels
                   class="elevation-0">
          <v-stepper-header>
            <template v-for="n in 2">
              <v-stepper-step :key="`${n}-step`"
                              :complete="step > n"
                              :step="n">
                <span class="text-stepper">{{ stepTitles[n - 1] }}</span>
              </v-stepper-step>

              <v-divider v-if="n < 2"
                         :key="`${n}-divider`" />
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content v-for="n in 2"
                               :key="`${n}-content`"
                               :step="n" />
          </v-stepper-items>
        </v-stepper>

        <v-window v-model="step">
          <v-window-item :value="1">
            <v-card-text class="py-0">
              <v-form>
                <v-row justify="center">
                  <v-col cols="12"
                         md="12">
                    <v-card-text class="py-0">
                      <v-row v-if="vehicleCapture.status.key === 'SR_STATUS_SAVED'"
                             align="start">
                        <v-col cols="12"
                               class="text-right">
                          <v-btn text
                                 large
                                 color="primary"
                                 @click="showVehicleCaptureDialog=true;">
                            <v-icon>mdi-plus-circle-outline </v-icon>
                            Add a vehicle
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12"
                               md="12">
                          <sb-data-table :headers="BULK_SINGLE_VEHICLE_UPLOAD_HEADERS['vehicleInfo']"
                                         :items="vehicleCapture.vehicleCapture.vehicles"
                                         default-pagination
                                         empty-message="No Vehicle Infomation"
                                         empty-sub-message="No Vehicle Infomation at this time"
                                         show-actions
                                         :show-errors="true"
                                         :show-erros-as-list="true"
                                         @selected-item="selectedItem" />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-window-item>
          <v-window-item :value="2">
            <v-card-text class="py-0">
              <v-form>
                <v-row justify="center">
                  <v-col cols="12"
                         md="12">
                    <sb-checkbox v-model="confirmation"
                                 label="Do you confirm that the information supplied is correct and complete?"
                                 @click="canSubmit" />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-window-item>
        </v-window>
      </sb-card>
      <div class="text-center">
        <v-dialog v-model="showPendingErrorsMessage"
                  width="300">
          <v-card>
            <v-card-text>
              Pending Validation Errors
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary"
                     block
                     @click="hideShowPendingErrorsMessage">
                Close Dialog
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <v-card-actions>
        <v-btn :disabled="step === 1"
               outlined
               large
               color="secondary"
               @click="step--">
          Back
        </v-btn>
        <v-spacer />

        <v-btn v-if="step === 1 && vehicleCapture.status.key === 'SR_STATUS_SAVED'"
               color="secondary"
               depressed
               large
               :disabled="clientNotselected"
               @click="step++">
          Next
        </v-btn>

        <v-btn v-if="step === 2"
               :disabled="vehicleCapture.vehicleCapture.vehicles.length === 0"
               color="secondary"
               depressed
               large
               @click="saveRequest">
          Save
        </v-btn>
        <v-btn v-if="step === 2 && vehicleCapture.status.key === 'SR_STATUS_SAVED'"
               color="success"
               depressed
               :disabled="canSubmitRequest"
               large
               @click="submitRequest">
          Create Request
        </v-btn>
      </v-card-actions>
    </v-container>
  </sb-page-content>
</template>

<script>
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import { VEHICLE_CAPTURE_SUBMITTED_TABLE_HEADERS, BULK_SINGLE_VEHICLE_UPLOAD_HEADERS, SR_STATUSES, STATUS_COLORS } from '@/config/constants';
import SbDataTable from '@/components/core/SbDataTable';
import SbSingleVehicleCaptureDialog from '@/components/vehicleCapture/SbSingleVehicleCaptureDialog';
import Audit from '@/models/Audit';
import Vehicle from '../../../models/vehicleCapture/Vehicle';
import VehicleCapture from '../../../models/serviceRequest/VehicleCapture';
import ServiceRequest from '../../../models/serviceRequest/ServiceRequest';

export default {
  components: {

    SbDataTable,
    SbSingleVehicleCaptureDialog,

  },
  props: {
    vehicleCaptureRequestNumber: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      VEHICLE_CAPTURE_SUBMITTED_TABLE_HEADERS,
      BULK_SINGLE_VEHICLE_UPLOAD_HEADERS,
      vehicle: new Vehicle(),
      vehicleCapture: new VehicleCapture(),
      ServiceRequest: new ServiceRequest(),
      clientNumber: '',
      clinetName: '',
      step: 1,
      stepTitles: ['Vehicles', 'Confirmation'],
      confirmation: false,
      vehicleEntity: {},
      vehicleInfomationData: [],
      selectedVehicleTemplate: '',
      selectedItemIndex: -1,
      selectedAction: '',
      previousVehicleEntity: {},
      tab: null,
      showVehicleCaptureDialog: false,
      clientNotselected: false,
      requestTypeNotSelected: false,
      manualTypeSelected: true,
      canSubmitRequest: true,
      vehicleCount: 0,
      showPendingErrorsMessage: false,
      documentsList: [],

    };
  },

  computed: {
    currentTitle() {
      return this.stepTitles[this.step - 1];
    },

  },

  watch: {
    vehicleCaptureRequestNumber: {
      handler(value) {
        this.displayVehicleCapture(value);
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('serviceRequests', [
      'createServiceRequest',
      'getServiceRequest',
      'updateServiceRequestEx',
    ]),

    ...mapActions('vehicleCapture', ['bulkVehicleUploadTemplate', 'addTransactionItem', 'removeTransationItem', 'editTransactionItem']),

    ...mapActions('serviceRequests', ['createServiceRequest', 'getServiceRequest', 'updateServiceRequestEx', 'updateServiceRequestV']),

    ...mapWaitingActions('serviceRequests', {
      createServiceRequest: 'serviceRequestLoading',
      getServiceRequest: 'getServiceRequestLoading',
      updateServiceRequestEx: 'updateServiceRequestExLoading',
    }),

    getStatusColor(status) {
      return STATUS_COLORS[status];
    },

    async displayVehicleCapture(value) {
      this.serviceRequest = new ServiceRequest();
      this.vehicleCapture = new VehicleCapture();

      if (value) {
        const request = await this.getServiceRequest(value);

        this.vehicleCapture.id = request.id;
        this.vehicleCapture.vehicleCapture = request.vehicleCapture;
        this.vehicleCapture.serviceRequestNumber = request.serviceRequestNumber;
        this.vehicleCapture.applicationType = request.applicationType;
        this.vehicleCapture.createdTimestamp = request.createdTimestamp;
        this.vehicleCapture.status = request.status;
        this.vehicleCapture.audit = request.audit;
        this.vehicleCapture.closedTimestamp = request.closedTimestamp;
        this.vehicleCount = request.vehicleCapture.vehicles.length;
        this.customerInfomation = request.vehicleCapture.customerInfomation;
        this.confirmation = request.vehicleCapture.confirmation;

        if (request.vehicleCapture.confirmation === '') {
          this.confirmation = false;
        } else {
          this.confirmation = true;
        }

        this.vehicleCapture.hasErrors = false;

        if (request.vehicleCapture.vehicles.length > 0) {
          for (let index = 0; index < request.vehicleCapture.vehicles.length; index += 1) {
            if (request.vehicleCapture.vehicles[index].hasErrors) {
              this.vehicleCapture.hasErrors = request.vehicleCapture.vehicles[index].hasErrors;
            }
          }
        }
      }
    },

    async addVehicleItem(item) {
      let vehicles = [];

      this.vehicleCapture.hasErrors = item.hasErrors;

      const payload = {
        item,
        vehicleCapture: this.vehicleCapture,
        selectedItemIndex: this.selectedItemIndex,
        previousItem: this.previousVehicleEntity,
      };

      if (this.selectedAction === 'Edit') {
        vehicles = await this.editTransactionItem(payload);
        this.editOrAddVehicle(vehicles);
        this.selectedAction = '';
        return;
      }

      vehicles = await this.addTransactionItem(payload);

      this.editOrAddVehicle(vehicles);
      this.resetForm();
    },

    editOrAddVehicle(vehicles) {
      this.vehicleCapture.vehicleCapture.vehicles = vehicles;
    },

    resetForm() {
      this.dialog = false;
      this.vehicle = new Vehicle();
    },

    canSubmit() {
      if (this.confirmation === false) {
        if (this.vehicleCapture.hasErrors) {
          // display pop up message
          this.showPendingErrorsMessage = true;
        } else {
          this.canSubmitRequest = false;
        }
      }
    },

    hideShowPendingErrorsMessage() {
      this.showPendingErrorsMessage = false;
      this.confirmation = false;
    },

    async submitRequest() {
      if (this.vehicleCapture.serviceRequestNumber) {
        this.vehicleCapture.vehicleCapture.confirmation = this.confirmation;
        await this.updateVehicleCapture(SR_STATUSES.SR_STATUS_IN_PROGRESS.value);
      } else {
        await this.createVehicleCapture(SR_STATUSES.SR_STATUS_IN_PROGRESS.value);
      }
      await this.displayVehicleCapture(this.vehicleCapture.serviceRequestNumber);
    },

    async saveRequest() {
      if (this.vehicleCapture.serviceRequestNumber) {
        this.vehicleCapture.vehicleCapture.confirmation = this.confirmation;
        await this.updateVehicleCapture(SR_STATUSES.SR_STATUS_SAVED.value);
      } else {
        await this.createVehicleCapture(SR_STATUSES.SR_STATUS_SAVED.value);
      }
      await this.displayVehicleCapture(this.vehicleCapture.serviceRequestNumber);

      await this.uploadUpdatedDocuments();
    },

    async uploadUpdatedDocuments() {
      // check

      // for (let index = 0; index < this.vehicleCapture.vehicleCapture.vehicles.length; index += 1) {
      //   if (this.vehicleCapture.vehicleCapture.vehicles[index].driverInformation.proofOfbanking.length > 0) {
      //     const payload = {
      //       fileType: '',
      //       files: this.vehicleCapture.vehicleCapture.vehicles[index].driverInformation.proofOfbanking,
      //       serviceRequestId: this.vehicleCapture.serviceRequestNumber,
      //     };
      //     // await this.uploadVehicleCaptureDocuments(payload, this.fileType);
      //   }
      // }
    },
    async uploadVehicleCaptureDocuments(payload, key) {
      const response = await this.uploadAttachments(payload);
      if (response.length === 0) {
        this.documentsList.push({
          key,
          uploaded: false,
        });
      }
    },

    async updateVehicleCapture(vehicleCaptureStatus) {
      if (vehicleCaptureStatus) {
        this.vehicleCapture.status.key = vehicleCaptureStatus;
      }

      await this.updateVehicleCaptureServiceRequest(this.vehicleCapture);
    },

    async updateVehicleCaptureServiceRequest(payloadVehicleCapture) {
      const payload = {
        serviceRequest: payloadVehicleCapture,
        audit: new Audit(),
      };

      await this.updateServiceRequestV(payload);
    },

    async selectedItem(selectedItem) {
      const copySelectedItem = JSON.parse(JSON.stringify(selectedItem));

      this.selectedAction = copySelectedItem.selectedAction;

      this.selectedItemIndex = copySelectedItem.selectedIndex;
      const payload = {
        item: copySelectedItem.selectedItem,
        vehicleCapture: this.vehicleCapture.vehicleCapture,
        selectedItemIndex: this.selectedItemIndex,
      };
      await this.handleSelectedItem(copySelectedItem, payload);
    },

    async onSelectedVehicle(value) {
      const payload = {
        vehicleNumber: value.vehicleNumber,
        clientId: this.clientId,
      };
      const results = await this.getVehicleTemplate(payload);
      this.$emit('template', results);
    },

    async handleSelectedItem(selectedItem, payload) {
      let vehicles = [];
      if (this.selectedAction === 'Edit') {
        this.showVehicleCaptureDialog = true;
        this.vehicleEntity = { ...selectedItem.selectedItem };
        this.previousVehicleEntity = JSON.parse(JSON.stringify(selectedItem.selectedItem));

        return;
      }

      if (this.selectedAction === 'Remove') {
        vehicles = await this.removeTransationItem(payload);
      }
      this.vehicleCapture.vehicleCapture.vehicles = vehicles;
    },
    onModalClose() {
      this.showVehicleCaptureDialog = !this.showVehicleCaptureDialog;
      this.vehicleEntity = {};
    },

  },
};
</script>
